import { gsap, MorphSVGPlugin } from "gsap/all";
gsap.registerPlugin(MorphSVGPlugin)

class FngButton extends HTMLAnchorElement {

  constructor() {
    super();

    this.pathDefault = this.querySelector('.shape-default');
    this.pathsActive = gsap.utils.toArray(this.querySelectorAll('.shape-active')).map(el => el.getAttribute("d"));

    this.addEventListener('mouseenter', (event) => {
      this.animationPlay(event);
    });
    this.addEventListener('mouseleave', (event) => {
      this.animationRewind(event);
    });
  }

  animationPlay(event) {
    const targetPath = gsap.utils.random(this.pathsActive, false);
    gsap.to(this.pathDefault, { duration: 0.2, morphSVG: targetPath });
  }

  animationRewind(event) {
    gsap.to(this.pathDefault, { duration: 0.2, morphSVG: this.pathDefault });
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('fng-button', FngButton, {'extends': 'a'});
}
