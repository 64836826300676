export default class AdjustDimensions {

  constructor() {
    this.elementsHeight = document.querySelectorAll('[data-adjust-height="1"]');
    this.elementsWidth = document.querySelectorAll('[data-adjust-width="1"]');

    window.addEventListener('resize', (event) => {
      this.resizeHandler();
    });
    setTimeout(() => {
      this.resizeHandler();
    }, 500);
  }

  resizeHandler() {
    const gridSize = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--grid-size'));

    this.elementsWidth.forEach((currentElement) => {
      currentElement.style.width = 'auto';
      const rect = currentElement.getBoundingClientRect();
      let targetSize = 0;
      while (targetSize < rect.width) {
        targetSize += gridSize;
      }
      currentElement.style.width = targetSize + 'px';
    });

    this.elementsHeight.forEach((currentElement) => {
      currentElement.style.height = 'auto';
      let targetSize = 0;
      const height = currentElement.clientHeight;
      while (targetSize < height) {
        targetSize += gridSize;
      }
      currentElement.style.height = targetSize + 'px';
    });

  }

}
