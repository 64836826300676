import Accordion from 'accordion-js';

export default class FngAccordion {

  constructor(selector) {
    this.accordionWrappers = document.querySelectorAll(selector);
    if (!this.accordionWrappers.length) {
      return;
    }

    for (let i = 0; i < this.accordionWrappers.length; ++i) {
      if (this.accordionWrappers[i].dataset.accordionInitialized) { return }
      this.accordionWrappers[i].dataset.accordionInitialized = true;

      new Accordion('#' + this.accordionWrappers[i].id, {
        duration: 100,
        onOpen: (currentElement) => {
          const gridSize = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--grid-size'));
          let targetSize = 0;
          let elementHeight = currentElement.clientHeight;
          while (targetSize < elementHeight) {
            targetSize += gridSize;
          }
          currentElement.style.height = targetSize + 'px';
        },
        beforeClose: (currentElement) => {
          currentElement.style.height = 'auto';
        },
      });
    }

  }

}
