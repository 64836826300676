class FngButtonCookid extends HTMLAnchorElement {

  constructor() {
    super();

    this.addEventListener('click', (event) => {
      event.preventDefault();
      const updateEvent = new Event('cookies:update', { bubbles: true, cancelable: false});
      document.querySelector('body').dispatchEvent(updateEvent);
    });
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('fng-button-cookie', FngButtonCookid, {'extends': 'a'});
}
