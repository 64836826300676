import barba from '@barba/core';
import { gsap } from "gsap/all";
import Cookies from 'js-cookie';

import NavMobile from "./nav-mobile";
import Slider from "./slider";
import Header from "./header";
import Reveal from "./reveal";
import FngAccordion from "./accordion";
import AdjustDimensions from "./adjust-dimensions";

import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default class BarbaJs {

  constructor() {
    const body = document.querySelector('body');
    // const headerNavigation = document.querySelector('.header--navigation');
    const navigationLinks = document.querySelectorAll('.navigation--link');

    const getAnalyticsContent = function() {
      let analytics_consent = false;
      const cookie_status = Cookies.get('cookie_status');
      if (cookie_status) {
        const categories = cookie_status.split(',');
        analytics_consent = categories.includes('analytics');
      }
      return analytics_consent;
    }


    // Define dataLayer and the gtag function.
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }

    // Set default consent values
    gtag('consent', 'default', {
      'ad_storage': getAnalyticsContent() ? 'granted' : 'denied',
      'ad_user_data': getAnalyticsContent() ? 'granted' : 'denied',
      'ad_personalization': getAnalyticsContent() ? 'granted' : 'denied',
      'analytics_storage': getAnalyticsContent() ? 'granted' : 'denied',
    });

    // listen for cookie updates
    body.addEventListener('cookies:saved', (event) => {
      gtag('consent', 'update', {
        'ad_storage': event.detail.includes('analytics') ? 'granted' : 'denied',
        'ad_user_data': event.detail.includes('analytics') ? 'granted' : 'denied',
        'ad_personalization': event.detail.includes('analytics') ? 'granted' : 'denied',
        'analytics_storage': event.detail.includes('analytics') ? 'granted' : 'denied',
      });

      // history pushState to trigger GTM Tag
      const url = new URL(window.location);
      history.pushState({}, "", url);
    });


    barba.init({
      timeout: 5000,

      // debug: true,
      // cacheIgnore: true,
      // prefetchIgnore: true,

      // requestError: (trigger, action, url, response) => {
      //   console.log({
      //     trigger: trigger,
      //     action: action,
      //     url: url,
      //     response: response,
      //   })
      // },

      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba') || el.classList.contains('active')),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            setTimeout(() => {
              new Header();
              new NavMobile();
              new Slider('.js--slider');
              new Reveal('.js--reveal');
              new FngAccordion('.js--accordion');
            }, 1);

            setTimeout(() => {
              new AdjustDimensions();
            }, 50);

            return gsap.to(body, {
              opacity: 1,
              duration: 0.5,
              delay: 0.5,
            });
          },

          beforeLeave(data) {
            // headerNavigation.classList.add('fade-out');
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 0.5,
              delay: data.trigger.classList != undefined && data.trigger.classList.contains('navigation--header-link') ? 1 : 0.4,
            });
          },

          afterLeave(data) {
            window.scrollTo({
              top: 0,
              left: 0,
            });

            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');
          },

          beforeEnter(data) {
            // Set default consent values
            gtag('consent', 'default', {
              'ad_storage': getAnalyticsContent() ? 'granted' : 'denied',
              'ad_user_data': getAnalyticsContent() ? 'granted' : 'denied',
              'ad_personalization': getAnalyticsContent() ? 'granted' : 'denied',
              'analytics_storage': getAnalyticsContent() ? 'granted' : 'denied',
            });
            // push page view event
            // gtag('pageview');

            // headerNavigation.classList.remove('fade-out');
            navigationLinks.forEach(navigationLink => {
              navigationLink.classList.toggle('is-active', navigationLink.dataset.slug === data.next.namespace);
            });

            data.next.container.style.opacity = 0;
          },

          enter(data) {
            data.current.container.remove();
            // reinit JS
            setTimeout(() => {
              new Header();
              new NavMobile();
              new Slider('.js--slider');
              new Reveal('.js--reveal');
              new FngAccordion('.js--accordion');
            }, 1);

            setTimeout(() => {
              new AdjustDimensions();
            }, 50);
          },

          afterEnter(data) {
            ScrollTrigger.refresh();

            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 0.5,
              delay: 0.5,
            });
          }

        },
      ],

    });
  }


}
