import { gsap, MorphSVGPlugin, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(MorphSVGPlugin)
gsap.registerPlugin(ScrollTrigger)

class FngDeco extends HTMLElement {

  constructor() {
    super();

    // this.pathDefault = this.querySelector('.shape-default');
    // // create random path data
    // this.pathsDataActive = [];
    // for (let index = 0; index < 2; index++) {
    //   const points = [
    //     gsap.utils.random(125, 200, 2) + ' ' + gsap.utils.random(0, 75, 2),
    //     gsap.utils.random(125, 200, 2) + ' ' + gsap.utils.random(125, 200, 2),
    //     gsap.utils.random(0, 75, 2) + ' ' + gsap.utils.random(125, 200, 2),
    //     gsap.utils.random(0, 75, 2) + ' ' + gsap.utils.random(0, 75, 2),
    //   ];
    //   this.pathsDataActive.push(points.join(' '));
    // }
    // // set default path to random shape
    // this.pathDataDefault = gsap.utils.random(this.pathsDataActive, false);
    // this.pathDefault.setAttribute('points', this.pathDataDefault);
    // // remove pathDataDefault from target paths
    // this.pathsDataActive.splice(this.pathsDataActive.indexOf(this.pathDataDefault), 1);

    this.pathDefault = this.querySelector('.shape-default');
    // create random path data
    this.paths = [];
    for (let index = 0; index < 2; index++) {
      const points = [
        gsap.utils.random(125, 200, 2) + ' ' + gsap.utils.random(0, 75, 2),
        gsap.utils.random(125, 200, 2) + ' ' + gsap.utils.random(125, 200, 2),
        gsap.utils.random(0, 75, 2) + ' ' + gsap.utils.random(125, 200, 2),
        gsap.utils.random(0, 75, 2) + ' ' + gsap.utils.random(0, 75, 2),
      ];
      this.paths.push(points.join(' '));
    }

    this.pathDefault.setAttribute('points', this.paths[0]);
    this.pathTarget = this.paths[1];

    this.initScrollTrigger();
  }


  initScrollTrigger() {
    // const targetPath = gsap.utils.random(this.pathsDataActive, false);

    gsap.to(this.pathDefault, {
      scrollTrigger: {
        trigger: this.pathDefault,
        scrub: 1,
        start: "top bottom",
        end: "bottom top"
      },
      duration: 1,
      morphSVG: {
        shape: this.pathTarget,
        precision: 1,
        type: "rotational",
      },
    });
    gsap.to(this, {
      scrollTrigger: {
        trigger: this,
        scrub: 1,
        start: "top bottom",
        end: "bottom top"
      },
      duration: 1,
      translateY: '-=5vh',
      scale: '1.1',
    });
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('fng-deco', FngDeco);
}
