export default class Header {

  constructor() {
    this.html = document.querySelector('html');
    this.header = document.querySelector('.header');
    this.lastScrollTop = 0
    this.threshold = 25

    window.addEventListener('scroll', (event) => {
      this.scrollHandler();
    });
    this.scrollHandler();
  }

  scrollHandler() {
    this.header.classList.toggle('has-scrolled', this.html.scrollTop > this.threshold);

    // // scrolling up
    // if (this.html.scrollTop > this.threshold && this.lastScrollTop < this.html.scrollTop) {
    //   // this.headerLogo.classList.add('-translate-y-[100px]');
    //   this.header.classList.add('has-scrolled');
    // }

    // // scrolling down
    // if (this.lastScrollTop > this.html.scrollTop) {
    //   this.header.classList.remove('has-scrolled');
    // }

    // // save scroll position
    // this.lastScrollTop = this.html.scrollTop
  }

}
