import '@ungap/custom-elements';

import FngButton from "./components/fng-button";
import FngButtonFooter from "./components/fng-button-footer";
import FngButtonCookie from "./components/fng-button-cookie";
import FngDeco from "./components/fng-deco";

import BarbaJs from "./features/barba-js";

new BarbaJs();

document.documentElement.classList.replace('no-js', 'js');
